export const links = [
    {
      name: "Credit Card",
      submenu: true,
      sublinks: [
        {
          Head: "Featured Credit Card",
          sublink: [
            { name: " Credit Card", link: "/credit-card" },
            { name: " Au BAnk Lit Credit Card", link: "/credit-card/aubank-credit-card" },
            { name: "Sbi SimplyClick Credit Card", link: "/credit-card/sbi-simplyclick-credit-card" },
            { name: "IDFC First ClassicCredit Card", link: "/credit-card/idfcfirst-classic-credit-card" },
            { name: "Flipkart Axix Bank Credit Card", link: "/credit-card/axis-bank-flipkart-credit-card" },
            { name: "HDFC Bank Millennia Credit Card", link: "/credit-card/hdfc-bank/millennia-credit-card" },
            { name: " Eazy Diner Indusland Credit Card", link: "/credit-card/eazy-diner-indusind-bank-credit-card" },
          ],
        },
        {         
          Head: "Bank Credit Card",
          sublink: [
            { name: "Yes Bank", link: "/yes-bank/credit-card" },
            { name: "Hsbc bank", link: "/hsbc-bank/credit-card" },
            { name: "INDUSLAND BANK", link: "/induslnd-bank/credit-card" },
            { name: "BANK Of Baroda Bank", link: "/bank-of-baroda/credit-card" },
            { name: "Standard Charterned Bank", link: "/standard-bank/credit-card" },
            { name: "American Express Bank BANK", link: "/american-bank/credit-card" },
          ],
        },
        {
          Head: " Other Banks",
          sublink: [
            { name: "AU BanK", link: "/au-bank/credit-card" },
            { name: "SBI BANK", link: "/sbi-bank/credit-card" },
            { name: "HDFC BANK", link: "/hdfc-bank/credit-card" },
            { name: "IDFC BANK", link: "/idfc-bank/credit-card" },
            { name: "AXIX BANK", link: "/axix-bank/credit-card" },
            { name: "ICICI BANK", link: "/icici-bank/credit-card" },
         
          ],
        },
       
        {
          Head: "Best Card",
          sublink: [
            { name: "Fuel Card", link: "/credit-card/fuel-credit-card" },
            { name: "Travel Card", link: "/credit-card/travel-credit-card" },
            { name: "Premium Card", link: "/credit-card/best-premium-credit-card" },
            { name: "Cash Back Card", link: "/credit-card/best-cashback-credit-card" },
            { name: "Lifetime Free Card", link: "/credit-card/best-lifetime-free-credit-cards" },
           
          ],
        },
      ],
    },
   
    // {
    //   name: "Insurance",
    //   submenu: true,
    //   sublinks: [
    //     {
    //       Head: " Best Car Insurance",
    //       sublink: [
    //         { name: "Car Insurance", link: "/car-insurance" },
    //         { name: "icici lombard Insurance", link: "/car-Insurance/icici-lombard-car-insurance" },
    //         { name: "acko Insurance", link: "/car-insurance/acko-car-insurance" },
    //         { name: "Tata Aig Insurance", link: "/car-insurance/tata-aij-car-insurance" },
    //         { name: "Hdfc Argo Insurance", link: "/car-insurance/hdfc-car-insurance" }, 
    //         { name: "Bajaj Alliaz Insurance", link: "/car-insurance/bajaj-allianz-car-insurance" },
          
    //       ],
    //     },
    //     {
    //       Head: "Best Bike Insurance",
    //       sublink: [
    //         { name: "Bike Insurance", link: "/bike-insurance" },
    //         { name: "icici lombard Insurance", link: "/bike-insurance/icici-bike-insurance" },
    //         { name: "Future Generali Insurance", link: "/bike-insurance/future-bike-insurance" },
    //         { name: "Acko Insurance", link: "/bike-insurance/acko-bike-insurance" },
    //         { name: " Bajaj Alliaz Insurance", link: "/bike-insurance/bajaj-bike-insurance" }, 
    //         { name: "Hdfc Argo Insurance", link: "/bike-insurance/hdfc-bike-insurance" },
           
           
    //       ],
    //     },
    //     {
    //       Head: "Best Life Insurance",
    //       sublink: [
    //         { name: "Life Insurance", link: "/life-insurance" },
    //         { name: "icici Prudential Insurance", link: "/life-insurance/icici-life-insurance" },
    //         { name: "Aditya Birla Insurance", link: "/life-insurance/adityalife-insurance" },
    //         { name: "Max life Insurance", link: "/life-insurance/maxlife-insurance" }, 
    //         { name: "Hdfc life Insurance", link: "/life-insurance/hdfc-life-insurance" },
    //       ],
    //     },
    //     {
    //       Head: "Best Health Insurance",
    //       sublink: [
    //         { name: "Health Insurance", link: "/health-insurance" },
    //         { name: "Care Health Insurance", link: "/health-insurance/care-health-insurance" }, 
    //         { name: "Hdfc Health Insurance", link: "/health-insurance/hdfc-ergo-health-insurance" },
    //         { name: "Digit Health Insurance", link: "/health-insurance/digit-health-insurance" },
    //         { name: "Reliance Health Insurance", link: "/health-insurance/reliance-health-insurance" },
  
    //         { name: "Aditya Birla Health Insurance", link: "/health-insurance/aditya-health-insurance" },
            
          
         
    //       ],
    //     },
    //     {
    //       Head: "Best Travel Insurance",
    //       sublink: [
    //         { name: "Travel Insurance", link: "/travel-insurance" },
    //         { name: "Bajaj Travel Insurance", link: "/travel-insurance/bajaj-allianz-travel-insurance" }, 
    //         { name: "Tata-Aij Travel Insurance", link: "/travel-insurance/tata-aij-travel-insurance" },
    //         { name: "Care  Travel Insurance", link: "/travel-insurance/care-health-travel-insurance" },
    //         { name: "HDFC ERGO Travel Isurance", link: "/travel-insurance/hdfc-ergo-travel-insurance" },
    //       { name: " ICICI Lombard Travel Isurance", link: "/travel-insurance/icici-lombard-travel-insurance"},
            
          
         
    //       ],
    //     },
    //   ],
    // },
    // {
    //   name: "Demat" ,
      
    //   submenu: true,
    //   sublinks: [
        
    //     {
    //       Head: "Demat Accounts",
    //       sublink: [
    //         { name: "Demat", link: "/demat" },
    //         { name: "Fyers Demat Accounts", link: "/demat/fyers-demat-account" },
    //         { name: "Groww Demat Accounts", link: "/demat/groww-demat-account" },
    //         { name: "Upstock Demat Accounts", link: "/demat/upstox-demat-account" },
    //         { name: "Zerodha Demat Accounts", link: "/demat/zeroda-demat-account" },
    //         { name: "Angel One Demat Accounts", link: "/demat/angel-one-demat-account" },
    //       ],
    //     },
        
    //   ],
    // },
    // {
    //   name: "Get Loan",
    //   submenu: true,
    //   sublinks: [
    //     {
    //       Head: "Car loan",
    //       sublink: [
    //         { name: "Car Loan", link: "/car-loan" },
    //         // { name: "Life Insurance", link: "/" },
    //         // { name: "Bike Insurance", link: "/" },
    //         // { name: "Health Insurance", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "Home Loan",
    //       sublink: [
    //         { name: "Home Loan", link: "/home-loan" },
    //         // { name: "Life Insurance", link: "/" },
    //         // { name: "Bike Insurance", link: "/" },
    //         // { name: "Health Insurance", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "Personal Loan",
    //       sublink: [
    //         { name: "Personal Loan", link: "/personal-loan" },
    //         // { name: "Life Insurance", link: "/" },
    //         // { name: "Bike Insurance", link: "/" },
    //         // { name: "Health Insurance", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "Education Loan",
    //       sublink: [
    //         { name: "Education Loan", link: "/education-loan" },
    //         // { name: "Life Insurance", link: "/" },
    //         // { name: "Bike Insurance", link: "/" },
    //         // { name: "Health Insurance", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "Two Wheeler Loan",
    //       sublink: [
    //         { name: "Two Wheeler Loan", link: "/two-wheeler-loan" },
    //         // { name: "Life Insurance", link: "/" },
    //         // { name: "Bike Insurance", link: "/" },
    //         // { name: "Health Insurance", link: "/" },
    //       ],
    //     },
    //   ],
 
    // },
    
  ];
  