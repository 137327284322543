import React, { useEffect, useState } from 'react';
import { Insurance_API } from '../../Helper';
import PageCategoryInsurance from './PageCategoryInsurance';
import InsurancePagination from './InsurancePagination';
import CardUiInsurance from './CardUiInsurance';


const InsuranceUiPage = () => {
  const [insurance, setInsurances] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; 
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [categories, setCategories] = useState([]);


  useEffect(() => {
    async function fetchInsurance() {
      let url = Insurance_API;

      if (currentPage || selectedCategory) {
        url = `https://test.thefinmaster.com/console/react-app-directory/insurance-api.php?page=${currentPage}&limit=${pageSize}`;
        if (selectedCategory) {
          url += `&category=${selectedCategory}`;
        }
      }

      try {
        const response = await fetch(url);
        const data = await response.json();
        const transformedData = data.insurance.map((insurance, index) => ({
          ...insurance,
          id: `${insurance.page_url}-${index}`  // Modify the ID here
          // Modify the ID here
        }));
        setInsurances(transformedData);
// Extract unique categories from card
const uniqueCategories = [...new Set(transformedData.map(insurance => insurance.category_name))];
setCategories(uniqueCategories);
        // setLoans(data.loan || data); // Adjust depending on your API response structure
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchInsurance();
  }, [currentPage, pageSize, selectedCategory]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
    setActiveCategory(category);
  };

  return (
    <div>
      {/* Category Section */}
      <div>
        {/* <PageCategory onSelectCategory={handleCategoryChange} selectedCategory={selectedCategory} 
       activeCategory={activeCategory} /> */}
       {/* for Dynamically */}
       <PageCategoryInsurance onSelectCategory={handleCategoryChange}  categories={categories} 
       activeCategory={activeCategory} />
      </div>

      {/* Blog Card Section */}
      <div >
        <CardUiInsurance insurance={insurance} currentPage={currentPage} selectedCategory={selectedCategory} pageSize={pageSize} />
{/*  Sidebar */}
{/* <div>
    <SideBar />
</div> */}

      </div>

      {/* Pagination Section */}
      <div>
        <InsurancePagination onPageChange={handlePageChange} currentPage={currentPage} insurance={insurance} pageSize={pageSize}/>
      </div>
    </div>
  );
};

export default InsuranceUiPage;