import React from 'react'
import { Link } from 'react-router-dom'
import CardModal from '../cardComponents/CardModal'


const PersonalLoanCard = ({ image, cardImage,interest, LoanAmount,  processing_fee, bank_link, loan_amount,interest_rate }) => {
    return (
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6'>
<div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
    
        <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
            <img className='w-[100%] h-[100%] object-contain' alt='' src={image} />
        </div>

</div>

<div className='w-full md:pl-0 pl-4'>
    <p className='text-gray-500 font-medium'>Interest Rate</p>
    <h4 className='text-2xl py-0.5 font-bold'>{interest_rate ||"no"}</h4>
    <p className='text-gray-400 text-xs'>Max Limit 99 yrs</p>
</div>
<div className='w-full md:pl-0 pl-4'>
    <p className='text-gray-500 font-medium'>Loan Amount</p>
    <h4 className='text-2xl py-0.5 font-bold'>{loan_amount}</h4>
</div>
<div className='w-full md:pl-0 pl-4'>
    <p className='text-gray-500 font-medium'>Processing Fee</p>
    <h4 className='text-2xl py-0.5 font-bold'> { processing_fee} -</h4>
</div>

<div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
    <Link to={bank_link}>
        <button className='bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300'>
            View Plan
        </button>
    </Link>
    <Link to="/learnmore_car_detail">
    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
    </Link>
   
</div>
</div>
    )
}

export default PersonalLoanCard
