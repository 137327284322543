'use client';
import { Accordion } from 'flowbite-react'
import React from 'react'

const CardAccordion = ({ Card_features }) => {

    console.log("Card_featuresCard_features", Card_features)

    return (
        <>
            <Accordion>
                {
                    Card_features?.map((item, index) => (
                        
                            < Accordion.Panel >
                                <Accordion.Title>
                                    {item?.Subfeatures}
                                </Accordion.Title>
                                <Accordion.Content>
                                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                                       
                                            {/* <p className="text-cyan-600 hover:underline dark:text-cyan-500">
                                                What you should know
                                            </p> */}
                                            
                                        <p >
                                            {item?.description}
                                        </p>
                                       
                                    </p>
                                </Accordion.Content>
                            </Accordion.Panel>
                        
                    ))
                }

            </Accordion >
        </>
    )
}

export default CardAccordion
