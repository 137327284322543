import React, { useEffect, useState } from 'react';
import DematCard from './DematCard';
import DematPagination from './DematPagination';

const DematUiPage = () => {
  const [demat, setDemats] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of blogs per page

  useEffect(() => {
    async function fetchDemat() {
      const url = `https://test.thefinmaster.com/console/react-app-directory/demat-api.php?page=${currentPage}&limit=${pageSize}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        const transformedData = data.demat.map((demat, index) => ({
          ...demat,
          id: `${demat.page_url}-${index}`, // Modify the ID here
        }));
        setDemats(transformedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchDemat();
  }, [currentPage, pageSize]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      {/* Blog Card Section */}
      <div>
        <DematCard demat={demat} currentPage={currentPage} pageSize={pageSize} />
      </div>

      {/* Pagination Section */}
      <div>
        <DematPagination onPageChange={handlePageChange} currentPage={currentPage} demat={demat} pageSize={pageSize} />
      </div>
    </div>
  );
};

export default DematUiPage;




// import React, { useEffect, useState } from 'react';
// import { Demat_API } from '../../Helper';
// import DematCard from './DematCard';
// import PageCategoryDemat from './PageCategoryDemat';
// import DematPagination from './DematPagination';


// const DematUiPage = () => {
//   const [demat, setDemats] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const pageSize = 10; // Number of blogs per page
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [activeCategory, setActiveCategory] = useState(null);
//   const [categories, setCategories] = useState([]);


//   useEffect(() => {
//     async function fetchDemat() {
//       let url = Demat_API;

//       if (currentPage || selectedCategory) {
//         url = `https://test.thefinmaster.com/console/react-app-directory/demat-api.php?page=${currentPage}&limit=${pageSize}`;
//         if (selectedCategory) {
//           url += `&category=${selectedCategory}`;
//         }
//       }

//       try {
//         const response = await fetch(url);
//         const data = await response.json();
//         const transformedData = data.demat.map((demat, index) => ({
//           ...demat,
//           id: `${demat.page_url}-${index}`  // Modify the ID here
//           // Modify the ID here
//         }));
//         setDemats(transformedData);
// // Extract unique categories from card
// const uniqueCategories = [...new Set(transformedData.map(demat => demat.category_name))];
// setCategories(uniqueCategories);
//         // setLoans(data.loan || data); // Adjust depending on your API response structure
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     }

//     fetchDemat();
//   }, [currentPage, pageSize, selectedCategory]);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const handleCategoryChange = (category) => {
//     setSelectedCategory(category);
//     setCurrentPage(1);
//     setActiveCategory(category);
//   };

//   return (
//     <div>
//       {/* Category Section */}
//       <div>
//         {/* <PageCategory onSelectCategory={handleCategoryChange} selectedCategory={selectedCategory} 
//        activeCategory={activeCategory} /> */}
//        {/* for Dynamically */}
//        <PageCategoryDemat onSelectCategory={handleCategoryChange}  categories={categories} 
//        activeCategory={activeCategory} />
//       </div>

//       {/* Blog Card Section */}
//       <div >
//         <DematCard demat={demat} currentPage={currentPage} selectedCategory={selectedCategory} pageSize={pageSize} />
// {/*  Sidebar */}
// {/* <div>
//     <SideBar />
// </div> */}

//       </div>

//       {/* Pagination Section */}
//       <div>
//         <DematPagination onPageChange={handlePageChange} currentPage={currentPage} demat={demat} pageSize={pageSize}/>
//       </div>
//     </div>
//   );
// };

// export default DematUiPage;