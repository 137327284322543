import React, { useRef, useEffect, useState } from 'react';

const PageCategoryInsurance = ({ categories, onSelectCategory, activeCategory }) => {
  const categoryRefs = useRef([]);

  return (
    <div className="category-container">
      <button
        ref={(el) => (categoryRefs.current[0] = el)}
        onClick={() => onSelectCategory(null)}
        className={`category-button ${!activeCategory ? "active" : ""}`}
      >
        All
      </button>
      {categories.length > 0 && categories.map((category, index) => (
        <button
          ref={(el) => (categoryRefs.current[index + 1] = el)}
          onClick={() => onSelectCategory(category)}
          className={`category-button ${activeCategory === category ? "active" : ""}`}
          key={category}
        >
          {category}
        </button>
      ))}
    </div>
  );
};

export default PageCategoryInsurance;

