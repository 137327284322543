import React from 'react';
import { Link } from 'react-router-dom';

const DematCard = ({ demat, currentPage, selectedCategory, pageSize }) => {
  const filteredDemat = Array.isArray(demat) ? demat : [];

  // Filter by selected category and paginate the results
  const paginatedDemat = filteredDemat
    .filter((item) => item.category_name === "Demat") // Filtering out non-Demat entries
    .filter((item) => !selectedCategory || item.category_name === selectedCategory)
    .slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="flex justify-center px-4 sm:px-6 lg:px-1.5 mt-6">
      <div className="grid grid-cols-1 gap-6 w-full">
        {paginatedDemat.map((item, index) => (
          <div key={`${item.page_url}-${index}`} className="relative container mx-auto rounded-lg shadow-xl bg-white mt-4 border border-gray-200 hover:border-gray-300 transition duration-300">
            <div className="absolute top-0 right-0 mt-4 mr-4 flex gap-4">
              <a 
                href={item.bank_link}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
                  Apply Now
                </button>
              </a>
              <Link to={`/demat/${item.page_url}`}>
                <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
                  Learn More
                </button>
              </Link>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-16 p-6">
              <div className="flex items-center justify-center">
                <img src={item.image} alt="demat" className="w-full max-w-xs mx-auto" />
              </div>
              
              <div>
                <h2 className="text-lg font-bold mb-2">Account Opening Charges</h2>
                <p className="text-gray-700">{item.account_opening_chrg}</p>
              </div>
              <div>
                <h2 className="text-lg font-bold mb-2">Annual Maintenance Charges</h2>
                <p className="text-gray-700">{item.annual_mainten_chrg}</p>
              </div>
              <div>
                <h2 className="text-lg font-bold mb-2">Brokerage</h2>
                <p className="text-gray-700">{item.brokerage}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DematCard;


// import React from 'react';
// import { Link } from 'react-router-dom';

// const DematCard = ({ demat, currentPage, selectedCategory, pageSize }) => {
//     // Ensure that 'demat' is an array
//     const filteredDemat = Array.isArray(demat) ? demat : [];

//     // Filter by selected category and paginate the results
//     const paginatedDemat = filteredDemat
//         .filter((item) => !selectedCategory || item.category_name === selectedCategory)
//         .slice((currentPage - 1) * pageSize, currentPage * pageSize);

//     return (
//         <div className="flex justify-center px-4 sm:px-6 lg:px-8 mt-6">
//             <div className="relative container mx-auto rounded-lg shadow-xl bg-white mt-4 border border-gray-200 hover:border-gray-300 transition duration-300">
//                 {paginatedDemat.map((item, index) => (
//                     <div key={`${item.page_url}-${index}`} >
//                         {/* Buttons */}
//                         <div className="absolute top-0 right-0 mt-4 mr-4 flex gap-4 mb-4">
//                             <a 
//                                 href={item.bank}
//                                 target="_blank" 
//                                 rel="noopener noreferrer"
//                             >
//                                 <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
//                                     Apply Now
//                                 </button>
//                             </a>
//                             <Link to="/demat/upstox-demat-account">
//                                 <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
//                                     Learn More
//                                 </button>
//                             </Link>
//                         </div>

//                         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-16">
//                             {/* Content Blocks */}
//                             <div className="p-6 bg-white hover:shadow-md transition duration-300">
//                                 <div className="flex items-center justify-center">
//                                     <img src={item.image} alt="Image" className="w-full max-w-xs mx-auto" />
//                                 </div>
//                                 <div className="flex items-center justify-center mt-4">
//                                     {/* Rating */}
//                                     <span className="text-yellow-500">&#9733;</span>
//                                     <span className="text-yellow-500">&#9733;</span>
//                                     <span className="text-yellow-500">&#9733;</span>
//                                     <span className="text-gray-400">&#9733;</span>
//                                     <span className="text-gray-400">&#9733;</span>
//                                     <span className="ml-1 text-gray-500">(3)</span>
//                                 </div>
//                             </div>
//                             <div className="p-6 bg-white hover:shadow-md transition duration-300">
//                                 <h2 className="text-lg font-bold mb-2">Account Opening Charges</h2>
//                                 <p className="text-gray-700">{item.account_opening_chrg}</p>
//                             </div>
//                             <div className="p-6 bg-white hover:shadow-md transition duration-300">
//                                 <h2 className="text-lg font-bold mb-2">Annual Maintenance Charges</h2>
//                                 <p className="text-gray-700">{item.annual_mainten_chrg}</p>
//                             </div>
//                             <div className="p-6 bg-white hover:shadow-md transition duration-300">
//                                 <h2 className="text-lg font-bold mb-2">Brokerage</h2>
//                                 <p className="text-gray-700">{item.brokerage}</p>
//                             </div>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default DematCard;
