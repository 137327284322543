import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";
import Http from '../../Http'; // Adjust the path as necessary
import { Blog_Api } from '../../Helper'; // Adjust the path as necessary

const SkeletonLoader = () => (
  <div className="skeleton-loader">
    <div className="skeleton-title mb-2"></div>
    <div className="skeleton-line mb-2"></div>
    <div className="skeleton-line"></div>
  </div>
);

const SideBar = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true); // Start loading
        const response = await Http.post(Blog_Api);
        setBlogs(response.blogs);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchBlogs();
  }, []);

  // Memoize sorted blogs to avoid unnecessary recalculation
  const sortedBlogs = useMemo(
    () => [...blogs].sort((a, b) => new Date(b.content_date) - new Date(a.content_date)),
    [blogs]
  );

  const latestBlogs = useMemo(() => sortedBlogs.slice(0, 10), [sortedBlogs]);
  const popularBlogs = useMemo(() => sortedBlogs.slice(10, 20), [sortedBlogs]);

  return (
    <div className='p-4 w-full'>
      {/* Latest Blogs Section */}
      <div>
        <h3 className='text-2xl font-semibold px-4'>Latest Blogs TheFinmaster</h3>
        <div>
          {loading ? (
            Array.from({ length: 5 }).map((_, index) => <SkeletonLoader key={index} />) // Show skeletons while loading
          ) : (
            latestBlogs.map(blog => (
              <div key={blog.content_url} className='my-5 border-b-2 pb-2 px-4'>
                <h4 className='text-base hover:text-blue-600 inline-flex items-center'>{blog.content_title}</h4>
                <div>
                  <Link to={`/blog_page/${blog.content_url}`} className='font-medium hover:text-blue-600 inline-flex items-center py-1'>
                    Read Now <FaArrowRight className='mt-1 ml-2'/>
                  </Link>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Popular Blogs Section (actually, the next set of blogs after Latest Blogs) */}
      <div className='mt-20'>
        <h3 className='text-2xl font-semibold px-4'>Popular Blogs TheFinmaster</h3>
        <div>
          {loading ? (
            Array.from({ length: 5 }).map((_, index) => <SkeletonLoader key={index} />) // Show skeletons while loading
          ) : (
            popularBlogs.map(blog => (
              <div key={blog.content_url} className='my-5 border-b-2 pb-2 px-4'>
                <h4 className='text-base hover:text-blue-600 inline-flex items-center'>{blog.content_title}</h4>
                <div>
                  <Link to={`/blog_page/${blog.content_url}`} className='font-medium hover:text-blue-600 inline-flex items-center py-1'>
                    Read Now <FaArrowRight className='mt-1 ml-2'/>
                  </Link>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar;

// import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom';
// import { FaArrowRight} from "react-icons/fa6";
// const SideBar = () => {
//     const [popularBlogs, setPopularBlogs] = useState([]);
//  useEffect(() => {
//     fetch("https://test.thefinmaster.com/console/react-app-directory/test-blog-api.php")
//     .then(res => res.json()).then(data => setPopularBlogs(data.blogs.slice(0,15)))
//  }, [])
//     return (
//     <>
//     <div>
//         <div >
//       <h3 className='text-2xl font-semibold px-4 '> Latest Blogs TheFinmaster</h3>
//       <div>
//         {
//             popularBlogs.slice(0, 5).map(blog => <div key ={blog.id} className='my-5 border-b-2 border-spacing-2 px-4'>
//         <h4 className='text-base pb-2 hover:text-blue-600 inline-flex items-center'>{blog.content_title}</h4>
//         <Link to ={`/blogs1/${blog.id}`}  className='font-medium hover:text-blue-600 inline-flex items-center py-1'>Read Now <FaArrowRight className='mt-1 ml-2'/></Link>
//         </div>)
//         }
//       </div>
//       </div>


//       <div >
//       <h3 className='text-2xl font-semibold px-4 mt-20'> PopularBlogs TheFinmaster</h3>
//       <div>
//         {
//             popularBlogs.slice(6, 10).map(blog => <div key ={blog.id} className='my-5 border-b-2 border-spacing-2 px-4'>
//         <h4 className='text-base pb-2 hover:text-blue-600 inline-flex items-center'>{blog.content_title}</h4>
//         <Link to ={`/blogs1/${blog.id}`}  className='font-medium hover:text-blue-600 inline-flex items-center py-1'>Read Now <FaArrowRight className='mt-1 ml-2'/></Link>
//         </div>)
//         }
//       </div>
//       </div>
//     </div>
//     </>
//   )
// }

// export default SideBar



// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { FaArrowRight } from "react-icons/fa6";

// const SideBar = () => {
//   const [popularBlogs, setPopularBlogs] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchPopularBlogs = async () => {
//       try {
//         const response = await fetch("http://test.thefinmaster.com/console/react-app-directory/test-blog-api.php");
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         const data = await response.json();
        
//         // Log data to understand the structure
//         console.log("Fetched Data:", data);

//         // Check if data.blogs is an array before setting the state
//         if (Array.isArray(data.blogs)) {
//           setPopularBlogs(data.blogs.slice(0, 15));
//         } else {
//           throw new Error("Unexpected data format: 'blogs' property is not an array.");
//         }
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchPopularBlogs();
//   }, []);

//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   if (error) {
//     return <p>Error: {error}</p>;
//   }

//   return (
//     <>
//       <div>
//         <h3 className='text-2xl font-semibold px-4'>Latest Blogs</h3>
//         <div>
//           {popularBlogs.map(blog => (
//             <div key={blog.id}>
//               <h4>{blog.content_title}</h4>
//               <Link to="/" className='font-medium hover:text-blue-600 inline-flex items-center py-1'>
//                 Read Now <FaArrowRight className='mt-1 ml-2'/>
//               </Link>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// }

// export default SideBar;
