import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';

const SingleDematPage = () => {
  const { id } = useParams();
  const [demat, setDemat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const fetchDemat = async () => {
      try {
        const response = await fetch(`https://test.thefinmaster.com/console/react-app-directory/demat-api.php?id=${id}`);
        
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const foundDemat = data.demat.find(demat => demat.page_url === id);
        
        setDemat(foundDemat);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDemat();
  }, [id]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  if (loading) {
    return (
      <div className="spinnerContainer">
        <div className="spinner"></div>
        <div className="loader">
          <p>Loading...</p>
          <div className="words">
            <span className="word">Images</span>
            <span className="word">Title</span>
            <span className="word">Card</span>
            <span className="word">Bank Card</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!demat) {
    return <p>No Card found.</p>;
  }

  return (
    <>
      <Helmet>
        <title>{demat.metatitle}</title>
        <meta name='description' content={demat.metadesc} />
        <meta name='keywords' content={demat.metakeyword} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <FAQSchema faqs={demat.demat_faq} />
      <nav className="text-gray-500 text-sm my-4 ml-12" aria-label="Breadcrumb">
        <ol className="list-none p-0 inline-flex">
          <li className="flex items-center">
            <Link to="/" className="text-blue-600 hover:text-blue-800">Home</Link>
            <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link to="/demat_page" className="text-blue-600 hover:text-blue-800">Demat Page</Link>
          </li>
        </ol>
      </nav>
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row gap-12">
          <div className="lg:w-3/4">
            <div className="w-full mx-auto">
              <img
                src={demat.image}
                alt={demat.page_title}
                className="w-full h-auto rounded"
              />
            </div>
            <h2 className="text-3xl mt-8 font-bold mb-4 text-blue-500 cursor-pointer">
              {demat.page_title}
            </h2>
            <p className="text-2xl mt-4 font-semibold mb-4 text-gray-700">
              {demat.page_description}
            </p>
          </div>
        </div>
      </div>
      <div className="text-gray-700 text-base mb-6 px-4 sm:px-6 md:px-8 lg:px-12">
  {demat.page_details ? (
    <div dangerouslySetInnerHTML={{ __html: demat.page_details }}  className="scrollable-table" />
  ) : (
    <p>No additional details available.</p>
  )}
</div>
      <div className="max-w-4xl mx-auto py-6">
        <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
        {demat.demat_faq.length > 0 ? (
          <div className="space-y-4">
            {demat.demat_faq.map((faq, index) => (
              <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                <button
                  onClick={() => toggleAccordion(index)}
                  className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                >
                  {faq.question}
                  <svg
                    className={`w-6 h-6 transition-transform transform ${
                      activeIndex === index ? 'rotate-180' : ''
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d={activeIndex === index ? 'M19 9l-7 7-7-7' : 'M9 5l7 7 7-7'}
                    />
                  </svg>
                </button>
                {activeIndex === index && (
                  <div className="p-4 bg-gray-100">
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500">No FAQs available.</p>
        )}
      </div>
    </>
  );
};

export default SingleDematPage;




// import React, { useEffect, useState } from 'react';
// import { Link, useParams } from 'react-router-dom';
// // import SideBar from './SideBar';
// import { Helmet } from 'react-helmet';
// import FAQSchema from '../../FAQSchema';

// const SingleDematPage = () => {
//   const { id } = useParams();
// //   console.log('ID from useParams:', id);
//   const [demat, setDemat] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//     const [activeIndex, setActiveIndex] = useState(0);
//   useEffect(() => {
//     const fetchDemat = async () => {
//       try {
//         //  console.log(`Fetching card with ID: ${id}`);
//         const response = await fetch(`https://test.thefinmaster.com/console/react-app-directory/demat-api.php?id=${id}`);
        
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
        
//         const data = await response.json();
        
//         // Log data to understand the structure
//         // console.log("Fetched Data:", data);

//         // Extract the blogs array
//         // const blogs = data.blogs;
        
//         // Find the specific blog by ID
//         // const foundBlog = blogs.find(blog => blog.url === id);
//         const foundDemat = data.demat.find(demat => demat.page_url === id);

//         // console.log("Found loan:", foundLoan);
        
//         setDemat(foundDemat);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchDemat();
//   }, [id]);

//   const toggleAccordion = (index) => {
//     if (activeIndex === index) {
//         setActiveIndex(null);
//     } else {
//         setActiveIndex(index);
//     }
// };
//   if (loading) {
//     return<div class="spinnerContainer">
//     <div class="spinner"></div>
//     <div class="loader">
//       <p>loading</p>
//       <div class="words">
//         <span class="word">images</span>
//         <span class="word">Title</span>
//         <span class="word">Card</span>
//         <span class="word">Bank Card</span>
//       </div>
//     </div>
//   </div>
//   }

//   if (error) {
//     return <p>Error: {error}</p>;
//   }

//   if (!demat) {
//     return <p>No Card found.</p>;
//   }
// // const {content_category, content_date, 
// //     content_description, 
// //     content_image, 
// // content_keyword, content_meta,
// // content_meta_description,
// // content_title} = blogs[0];

//   return (
//     <>
//     <Helmet>
//         <title>{demat.metatitle}</title>
//         <meta name='description' content={demat.metadesc} />
//         <meta name='keywords' content={demat.metakeyword} />
//       </Helmet>
//       <FAQSchema faqs={demat.demat_faq} />
//       <nav className="text-gray-500 text-sm my-4 ml-12" aria-label="Breadcrumb">
//         <ol className="list-none p-0 inline-flex">
//           <li className="flex items-center">
//             <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
//             <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
//               <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
//             </svg>
//           </li>
//           <li className="flex items-center">
//             <Link to="/demat_page" className="text-blue-600 hover:text-blue-800">Loan Page</Link>
//             <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
//               <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
//             </svg>
//           </li>

//         </ol>
//       </nav>
//       <div className="container mx-auto px-4 py-8">
//   <div className="flex flex-col lg:flex-row gap-12">
//     <div className="lg:w-3/4">
//       <div className="w-full mx-auto">
//         <img
//           src={demat.image}
//           alt={demat.page_title}
//           className="w-full h-auto rounded"
//         />
//       </div>
//       <h2 className="text-3xl mt-8 font-bold mb-4 text-blue-500 cursor-pointer">
//         {demat.page_title}
//       </h2>
//       <p className="text-2xl mt-4 font-semibold mb-4 text-gray-700">
//         {demat.page_description}
//       </p>
      
//     </div>

//     {/* Uncomment and customize the sidebar as needed */}
//     {/* <div className='lg:w-1/4'>
//       <SideBar />
//     </div> */}
//   </div>
// </div>
// <div className="text-gray-700 text-base mb-6 px-12 ">
//         <p dangerouslySetInnerHTML={{ __html: demat.page_details }}></p>
//       </div>
//       <div className="max-w-4xl mx-auto py-6">
//                         <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
//                         <div className="space-y-4">
//                             {demat.demat_faq.map((faq, index) => (
//                                 <div key={index} className="border rounded-lg overflow-hidden shadow-md">
//                                     <button
//                                         onClick={() => toggleAccordion(index)}
//                                         className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
//                                     >
//                                         {faq.question}
//                                         <svg
//                                             className={`w-6 h-6 transition-transform transform ${
//                                                 activeIndex === index ? 'rotate-180' : ''
//                                             }`}
//                                             fill="none"
//                                             viewBox="0 0 24 24"
//                                             stroke="currentColor"
//                                         >
//                                             <path
//                                                 strokeLinecap="round"
//                                                 strokeLinejoin="round"
//                                                 strokeWidth="2"
//                                                 d={activeIndex === index ? 'M19 9l-7 7-7-7' : 'M9 5l7 7 7-7'}
//                                             />
//                                         </svg>
//                                     </button>
//                                     {activeIndex === index && (
//                                         <div className="p-4 bg-gray-100">
//                                             <p className="text-gray-600">{faq.answer}</p>
//                                         </div>
//                                     )}
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//     </>
  
//   );
// };

// export default SingleDematPage;
