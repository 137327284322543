import React, { useEffect, useState } from 'react'
import AccordionComponent from './accordion/AccordionComponent'
import CardComponent from './cardComponents/CardComponent'
import { CiGlobe } from 'react-icons/ci';
import { TbTransfer } from 'react-icons/tb';
import { PiGlobeHemisphereWestLight } from 'react-icons/pi';
import { MdOutlinePermContactCalendar } from 'react-icons/md';
import LoanCardComponent from './loadCardComponent/LoanCardComponent'
import CrouselComponent from './CarouselComponent/CrouselComponent'
import { MdGavel } from "react-icons/md";
import { GiTakeMyMoney } from "react-icons/gi";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { FaTools } from "react-icons/fa";
import Http from '../Http';
import { CARD_API } from '../Helper';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Newsletter from './contact/Newsletter';


const Home = () => {
    // const [CreditCardData, setCreditCardData] = useState([])
    // const [currentPage, setCurrentPage] = useState(1);
    // const [cardsPerPage] = useState(10);
    // const [currentPage_Personal, setCurrentPagePersonal] = useState(1);
    // const [cardsPerPage_Personal] = useState(10);
    // useEffect(() => {
    //     Http.post(CARD_API).then((res) => {
    //         setCreditCardData(res)
    //     }).catch((err) => {
    //         console.log(err)
    //     })
    // }, [])
    // const indexOfLastCard = currentPage * cardsPerPage;
    // const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    // console.log({ indexOfLastCard, indexOfFirstCard })
    // const currentCards = CreditCardData.cards?.filter((card) => card.category === "Credit card").slice(indexOfFirstCard, indexOfLastCard);
    // console.log({ currentCards })

    // const paginate = (pageNumber) => setCurrentPage(pageNumber);


    // const indexOfLastCard_Personal = currentPage_Personal * cardsPerPage_Personal;
    // const indexOfFirstCard_Personal = indexOfLastCard_Personal - cardsPerPage;
    // console.log({ indexOfLastCard, indexOfFirstCard_Personal })
    // const currentCards_Personal = CreditCardData.cards?.filter((card) => card.category === "Loan").slice(indexOfFirstCard_Personal, indexOfLastCard_Personal);
    // console.log({ currentCards })
    // const paginatePersonal = (pageNumber) => setCurrentPagePersonal(pageNumber);
    return (
        <>
            <Helmet>
                <title>Insurance - Compare Insurance Plans and Policies Online </title>
                <meta name='description' content='Compare insurance plans online and get free quotes for products like car insurance, term insurance, life insurance, health insurance and many other insurance products only at thefinmaster.com.' />
                <meta name='keywords' content='insurance comparison, compare car insurance, compare bike insurance, compare health insurance, life insurance comparison, compare credit cards, compare loan' />
                <link rel="canonical" href="https://thefinmaster.com/" />
                <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
                <meta name="robots" content="max-video-preview:-1" />
            </Helmet>
            <div className='w-full'>

                <div className='w-full flex flex-col items-center'>
                    <div className='w-full h-screen flex justify-center relative bg-black'>
                        <div className='w-[90%] z-20 flex flex-wrap md:flex-nowrap justify-center'>
                            <div className='w-full flex items-center'>
                                <div className='md:w-[80%] z-30'>
                                    <h1 className='font-bold text-4xl md:text-6xl text-white text-center md:text-start'>
                                        {/* Financial platform regulated in <span className='text-blue-600'>India</span> */}
                                        Welcome to TheFinMaster
                                    </h1>
                                    <p className='text-gray-100 py-5 font-medium text-xl text-center md:text-start'>
                                        Find, Compare, and Apply for the Ideal
                                        <p className='text-gray-100  font-medium text-xl text-center md:text-start'>Product Tailored to Your Needs!</p>
                                    </p>

                                    <div className='flex justify-center md:justify-start'>
                                        <button className='px-8 py-4 rounded-full bg-blue-600 shadow-lg text-white font-medium mt-2 text-lg'>Get Your Card</button>
                                    </div>
                                </div>
                            </div>

                            <div className='md:w-full z-30 md:flex mt-5 items-center justify-center'>
                                <video autoPlay muted loop className='mix-blend-exclusion'>
                                    <source src='/heroGif.mp4' type='video/mp4'></source>
                                </video>
                            </div>
                        </div>
                    </div>

                    <div className='w-[95%] my-10'>
                        {/* <h2 className='text-5xl font-bold text-center md:text-start pb-3'>Your Financial Solutions</h2> */}
                        <h2 className='text-4xl font-bold text-center  pb-3'>Your Financial Solutions</h2>

                        <p className='text-xl text-center  text-blue-600'>Everything You Need Under One Roof</p>

                        <div className='w-full flex flex-wrap md:flex-nowrap gap-8 py-10'>

                            <div className='w-full border border-b-4 border-b-blue-600 hover:border-b-black shadow-lg hover:shadow-2xl'>
                                <Link to="/car-loan">
                                    <div className='w-full h-[250px] overflow-hidden'>
                                    <figure className='relative w-full h-full'>
                                        <img className='w-[100%] h-[100%] object-cover hover:scale-110 duration-150' src='../assets/loanhomepage.webp' alt='Image of a home loan calculator with features for comparing loan policies, exploring auto loans, and getting pre-approved.' />
                                        <figcaption>
                                        <span className='sr-only' aria-hidden="true">  Get a Loan, Compare Loan Policy! Explore auto loans with competitive rates & flexible terms. Get pre-approved today!</span>

                                        </figcaption>
                                           </figure>
                                    </div>
                                </Link>
                                <div className='p-5'>
                                    <h1 className='text-lg font-medium'>Loans:</h1>
                                    <p className='text-sm text-gray-600 pt-2'>
                                        <ul className="list-disc list-inside">
                                            <li>Your Gateway to Financial Flexibility: Discover various loan products.</li>
                                            <li>From Auto to Home Loan: Explore a wide array of loan options.</li>
                                            <li>Fulfill Your Financial Goals: Learn how loans can help you achieve your dreams.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className='w-full border border-b-4 border-b-blue-600 hover:border-b-black shadow-lg hover:shadow-2xl'>
                                <Link to="/credit-card" >
                                    <div className='w-full h-[250px] overflow-hidden'>
                                    <figure className='relative w-full h-full'>
                                        <img className='w-[100%] h-[100%] object-cover hover:scale-110 duration-150' src='../assets/cardhome.webp' alt='Image of Mastercard credit cards. Get Credit Card, Compare Credit card. Snack on this & consider credit cards for cash back, travel points & more!' />
                                        <figcaption >
                                        <span className='sr-only' aria-hidden="true">  Image of Mastercard credit cards. Get Credit Card, Compare Credit card. Snack on this & consider credit cards for cash back, travel points & more!</span>
                                        </figcaption>
                                           </figure>
                                    </div>
                                </Link>
                                <div className='p-5'>
                                    <h1 className='text-lg font-medium'>Credit Cards:



                                    </h1>
                                    <p className='text-sm text-gray-600 pt-2'>
                                        <ul className="list-disc list-inside">
                                            <li>Unlock the Power of Plastic: Learn About Diverse Range of Credit Cards.</li>
                                            <li>Explore Various Credit Card Options Suited to Your Spending Habits.</li>
                                            <li>Find the Perfect Card for You: Compare Features, Rewards & Benefits.</li>
                                        </ul>
                                    </p>
                                </div>

                            </div>
                            <div className='w-full border border-b-4 border-b-blue-600 hover:border-b-black shadow-lg hover:shadow-2xl'>
                                <Link to="/demat">
                                    <div className='w-full h-[250px] overflow-hidden'>
                                    <figure className='relative w-full h-full'>
                                        <img className='w-[100%] h-[100%] object-cover hover:scale-110 duration-150' src='../assets/demathome.webp' alt='Get a Demat Account, Compare Deamt Accounts. Track investments, view real-time quotes, manage portfolio. Learn about Demat & Trading A/cs.' />
                                        <figcaption>
                                        <span className='sr-only' aria-hidden="true">  Track investments on your phone! See real-time quotes & manage your portfolio. Curious about Demat & Trading A/cs? Learn more!</span>
                                        </figcaption>
                                           </figure>
                                    </div>
                                </Link>
                                <div className='p-5'>
                                    <h1 className='text-lg font-medium'>Demat:</h1>
                                    <p className='text-sm text-gray-600 pt-2'>
                                        <ul className="list-disc list-inside">

                                            <li>Manage Your Investments: Learn About Demat Account Services.</li>
                                            <li>Ease Investments: Discover Demat Account Benefits.</li>
                                            <li>Invest Confidently: Find the Ideal Demat Account for Portfolio Growth.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className='w-full border border-b-4 border-b-blue-600 hover:border-b-black shadow-lg hover:shadow-2xl'>
                                <Link to="/car-insurance">
                                    <div className='w-full h-[250px] overflow-hidden'>
                                    <figure className='relative w-full h-full'>
                                        <img className='w-[100%] h-[100%] object-cover hover:scale-110 duration-150' src='../assets/insurancehome.webp' alt='Compare and Apply For Insurance. Secure your future with quotes for car, bike, life & travel insurance online.' />
                                        <figcaption>
                                        <span className='sr-only' aria-hidden="true">Secure your future!  Get quotes for car, bike, life & travel insurance online. Protect what matters most,</span>
                                        </figcaption>
                                           </figure>
                                    </div>
                                </Link>
                                <div className='p-5'>
                                    <h1 className='text-lg font-medium'>Insurance:</h1>
                                    <p className='text-sm text-gray-600 pt-2'>
                                        <ul className="list-disc list-inside">

                                            <li>Secure Your Future: Explore Comprehensive Insurance Offerings.</li>
                                            <li>Protect What Matters: Discover Insurance Plans Tailored to Your Needs.</li>
                                            <li>Find the Right Insurance Coverage for You and Your Loved Ones.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='w-full flex md:py-10 flex-col items-center'>
                        <div className='w-[90%] flex flex-wrap md:flex-nowrap justify-between py-10'>
                            <div className='md:w-[40%] flex justify-end items-center md:pr-5'>
                                <h2 className='font-bold text-3xl md:text-3xl md:w-[85%] text-center md:text-start'>
                                    Embark on financial empowerment by exploring, comparing, and making informed decisions with TheFinMaster.
                                </h2>
                            </div>
                            <div className='md:w-[60%]'>
                                <div className='flex flex-wrap gap-10 md:relative'>
                                    <div className='flex flex-col gap-10'>
                                        <div className='md:w-[300px] h-[200px] flex items-center justify-center rounded-xl shadow-2xl bg-white'>
                                            <div className='px-5'>
                                                <div className='flex items-center gap-2'>
                                                    <GiTakeMyMoney className='text-6xl text-blue-600' />
                                                    <h3 className='font-bold text-2xl text-gray-600'>Financial Hub</h3>
                                                </div>
                                                <p className='text-gray-500 text-lg pt-2 text-center'>
                                                    Hub of Finance Info: Loans, Cards, Demat, Insurance.
                                                </p>
                                            </div>
                                        </div>

                                        <div className='md:w-[300px] h-[200px] flex items-center justify-center rounded-xl hover:shadow-2xl hover:bg-white'>
                                            <div className='px-5'>
                                                <div className='flex items-center gap-2'>
                                                    <MdGavel className='text-5xl text-blue-600' />
                                                    <h3 className='font-bold text-2xl text-gray-600'>Smart Decisions</h3>
                                                </div>
                                                <p className='text-gray-500 text-lg pt-2 text-center'>
                                                    Compare 100+ Products, Make Informed Decisions for Financial Well-being.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-10 md:mt-10'>
                                        <div className='md:w-[300px] h-[200px] flex items-center justify-center rounded-xl hover:shadow-2xl hover:bg-white'>
                                            <div className='px-5'>
                                                <div className='flex items-center gap-2'>
                                                    <FaTools className='text-5xl text-blue-600' />
                                                    <h3 className='font-bold text-2xl text-gray-600 text-center'>Financial Toolbox</h3>
                                                </div>
                                                <p className='text-gray-500 text-lg pt-2 text-center'>
                                                    Access Tools for Smarter Financial Planning and Management.

                                                </p>
                                            </div>
                                        </div>
                                        <div className='md:w-[300px] h-[200px] flex items-center justify-center rounded-xl hover:shadow-2xl hover:bg-white'>
                                            <div className='px-5'>
                                                <div className='flex items-center gap-2'>
                                                    <BsFillPersonCheckFill className='text-5xl text-blue-600' />
                                                    <h3 className='font-bold text-2xl text-gray-600'>Expert Insights</h3>
                                                </div>
                                                <p className='text-gray-500 text-lg pt-2 text-center'>
                                                    Explore Expert Guides for Informed Financial Decision-Making.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Newsletter />
                    {/* <div class="px-4 sm:px-8 lg:px-10 mb-8">
                        <div class="w-full py-8 text-white bg-blue-600 rounded-lg shadow-lg">
                            <div class="max-w-[1240px] mx-auto flex flex-col lg:flex-row items-center lg:justify-between">
                                <div class="flex flex-col lg:flex-row items-center mb-4 lg:mb-0 lg:mr-4">
                                    <img src="../assets/newsletter.jpeg" alt="icon" class="w-48 h-40 mb-4 lg:mb-0 lg:mr-4" />
                                    <div class="text-center lg:text-left max-w-full lg:max-w-[60%]">
                                        <h1 class="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 whitespace-normal lg:whitespace-nowrap">
                                            Never miss out on exclusive offers and updates!
                                        </h1>
                                        <p class="text-lg sm:text-xl">Sign up now to receive personalized deals and stay in the loop.</p>
                                    </div>
                                </div>
                                <div class="flex flex-col sm:flex-row items-center w-full lg:w-auto mt-4 lg:mt-20 lg:mr-4">
                                    <input
                                        class="flex-grow lg:flex-grow-0 rounded-md text-black p-2 mb-4 sm:mb-0 sm:mr-4"
                                        type="email"
                                        placeholder="Enter Email"
                                    />
                                    <button class="rounded-md font-medium w-auto lg:w-[200px] px-6 py-3 text-white bg-black hover:bg-gray-900">
                                        Notify Me
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    <div className='w-full flex flex-col items-center'>
                        <div className='w-[90%] flex flex-wrap md:flex-nowrap justify-between'>
                            <div className='w-full'>
                                <img src='../assets/home1.webp' alt='img1' />
                            </div>
                            <div className='w-full flex items-center'>
                                <div>
                                    <h2 className='font-bold text-3xl'>Why TheFinMaster?</h2>
                                    {/* <ul className='py-5 text-xl font-medium'> */}
                                    {/* <li className='flex items-center gap-2'><TbTransfer className='text-blue-600' /> Physical card in Standard and Premium options</li>
                                        <li className='flex items-center gap-2'><PiGlobeHemisphereWestLight className='text-blue-600' /> Instantly issued Virtual card</li>
                                        <li className='flex items-center gap-2'><MdOutlinePermContactCalendar className='text-blue-600' /> Make purchases or withdraw cash from ATMs</li> */}
                                    <p className='py-5 text-xl font-medium text-gray-600'>TheFinMaster.com simplifies your finances! Explore & compare credit cards, loans, insurance & demat accounts from various providers. As a one-stop platform, we aim to simplify your financial journey by providing detailed insights into multiple products and offerings.</p>
                                    <p className='mt-0.5 text-xl font-medium text-gray-600'> Make informed financial decisions with our insightful guides and tools.</p>

                                    {/* <button className='border px-8 py-4 rounded-full bg-blue-600 text-white font-medium mt-2 text-lg'>Get Your Card</button> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex flex-col items-center mt-10 md:mt-0  '>
                        <div className='w-[90%] flex flex-wrap md:flex-nowrap justify-between '>

                            <div className='w-full md:hidden'>
                                <img src='../assets/home2.webp' alt='img1' />
                            </div>

                            <div className='md:w-full flex items-center'>
                                <div>
                                    <h2 className='font-bold text-3xl'>Master Financial Learning</h2>
                                    {/* <ul className='p  y-5  text-xl font-medium'> */}
                                    {/* <li className='flex items-center gap-2'><TbTransfer className='text-blue-600' /> Physical card in Standard and Premium options</li>
                                        <li className='flex items-center gap-2'><PiGlobeHemisphereWestLight className='text-blue-600' /> Instantly issued Virtual card</li>
                                        <li className='flex items-center gap-2'><MdOutlinePermContactCalendar className='text-blue-600' /> Make purchases or withdraw cash from ATMs</li> */}
                                    <p className='py-5  text-xl font-medium text-gray-600'>Stay informed and empowered with our insightful blogs. Explore the latest trends, government policies, and financial tips to enhance your financial literacy and make informed decisions.
                                    </p>
                                    <p className=' mt-0.5 text-xl font-medium text-gray-600'> Dive into our blogs and enrich your financial knowledge today!</p>
                                    {/* </ul> */}
                                    {/* <ul className='py-5 text-xl font-medium'>
                                        <li className='text-base'>
                                            <span className='text-lg font-semibold text-blue-600'>Credit Cards:</span> Discover a world of possibilities with our detailed credit card comparisons. From cashback offers to travel rewards, we break down the features, fees, and benefits, helping you find the card that aligns perfectly with your lifestyle and spending habits.
                                        </li>
                                        <li className='py-2 text-base'>
                                            <span className='text-lg font-semibold text-blue-600'>Insurance:</span> Protecting what matters most is crucial. Explore our extensive range of insurance comparisons, including life, health, auto, and home insurance. Make informed decisions based on coverage, premiums, and policy terms.
                                        </li>
                                        <li className='text-base'>
                                            <span className='text-lg font-semibold text-blue-600'>Loans:</span> Whether it's a personal loan, home loan, or car loan, we simplify the loan comparison process. Find the best interest rates, repayment terms, and loan amounts to suit your financial needs.
                                        </li>
                                        <li className='py-2 text-base'>
                                            <span className='text-lg font-semibold text-blue-600'>Demat Accounts:</span> For savvy investors, our demat account comparisons are designed to help you choose the right platform for your stock trading and investment needs. Compare brokerage charges, user interface, and additional features effortlessly.
                                        </li>
                                    </ul> */}
                                    {/* <button className='border px-8 py-4 rounded-full bg-blue-600 text-white font-medium mt-2 text-lg'>
                                        Open Account
                                    </button> */}
                                </div>
                            </div>
                            <div className='md:w-full hidden md:block'>
                                <img src='../assets/home2.webp' alt='img2' />
                            </div>

                        </div>
                    </div>


                    {/* <h1 className='text-center font-bold text-3xl md:text-5xl my-10 mt-20'>Featured Credit Cards</h1>
                    <div className='w-[95%] mx-auto flex gap-5 flex-wrap md:flex-nowrap justify-center'>
                        <div className='w-fit flex flex-col gap-8'>
                            {
                                currentCards &&
                                currentCards.map((item, index) => {
                                    return <> <CardComponent key={index} Card_features={item?.Card_features} fees={item?.fees} cardName={item?.title} cardImage={item?.image} rating={item?.rating} category={item?.category} status={item?.status} /> </>
                                })
                            }   </div>

                        <div className='w-full relative '>
                            <div className='sticky overflow-hidden top-20 shadow-lg w-full h-[230px] rounded-2xl bg-blue-500 border flex flex-col justify-between'>
                                <div className='w-[70%] mx-auto'>
                                    <h2 className='text-center font-medium pt-5 text-lg'><span className='font-semibold text-white'>816 people</span> are on call with our advisors right now</h2>
                                </div>
                                <div className='w-full mx-auto'>
                                    <div className='w-[70%] mx-auto'>
                                        <img src='https://static.pbcdn.in/health-cdn/images/insurer-logo/quotes-logos/advisors-logo.png' alt='imgg' />
                                    </div>
                                    <div className='bg-white'>
                                        <h3 className='text-center text-blue-500 cursor-pointer font-semibold py-2'>Know your advisor</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ul className='flex justify-center mt-4'>
                        {currentCards &&
                            CreditCardData.cards?.filter((card) => card.category === "Credit card").length > 0 &&
                            Math.ceil(CreditCardData.cards?.filter((card) => card.category === "Credit card").length / cardsPerPage) > 1 &&
                            Array.from({ length: Math.ceil(CreditCardData.cards?.filter((card) => card.category === "Credit card").length / cardsPerPage) }).map((_, index) => (
                                <li key={index} className='mx-1'>
                                    <button
                                        className={`px-3 py-1 rounded-lg ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                                            }`}
                                        onClick={() => paginate(index + 1)}
                                    >
                                        {index + 1}
                                    </button>
                                </li>
                            ))}
                    </ul>

                    <div className='w-full my-20'>
                        <div className='w-full flex flex-col items-center justify-center bg-black h-[200px] md:h-[300px] shadow-lg mb-5 border-b-8 border-blue-600'>
                            <h1 className='text-white text-3xl md:text-7xl text-centerz-20 font-medium'>
                                Personal Loan
                            </h1>
                            <p className='text-gray-200 text-2xl text-center md:text-start px-2 pt-2'>Unlock your financial potential with our personalized personal loan solutions.</p> */}
                    {/* <img className='w-[100%] h-[100%] object-cover' src='https://www.kotak.com/content/dam/Kotak/guidance/hero-slider-banner/hacks-for-being-regular-with-personal-loan-repayments-hero-desktop.jpg' alt='personalLoan' /> */}
                    {/* </div> */}

                    {/* 
                        <div className='w-[95%] mx-auto flex gap-5 flex-wrap md:flex-nowrap justify-center'>
                            <div className='w-fit flex flex-col gap-8 mt-5'>
                                {currentCards_Personal &&
                                    currentCards_Personal.map((item, index) => (
                                        <LoanCardComponent
                                            key={index}
                                            Card_features={item?.Card_features}
                                            fees={item?.fees}
                                            cardName={item?.title}
                                            cardImage={item?.image}
                                            rating={item?.rating}
                                            category={item?.category}

                                        />
                                    ))}
                            </div> */}

                    {/* <div className='w-full relative mt-5'>
                                <div className='sticky overflow-hidden top-5 shadow-lg w-full rounded-lg bg-blue-500 border flex flex-col justify-between'> */}

                    {/* <div className='w-full mx-auto'>
                                        <div className='w-full mx-auto'>
                                            <img src='https://creditloanz.com/wp-content/uploads/2022/12/HDFC-Bank-Personal-Loan.png' alt='imgg' />
                                        </div>
                                        <div className='bg-white'>
                                            <h3 className='text-center text-blue-500 cursor-pointer font-semibold py-2'>Know your advisor</h3>
                                        </div>
                                    </div> */}
                    {/* </div>
                        </div> 
                        </div> */}
                    {/* Pagination */}
                    {/* <ul className='flex justify-center mt-4'>
    {cardsPerPage_Personal &&
        CreditCardData.cards?.filter((card) => card.category === "Loan").length > 0 &&
        Math.ceil(CreditCardData.cards?.filter((card) => card.category === "Loan").length / cardsPerPage_Personal) > 1 &&
        Array.from({ length: Math.ceil(CreditCardData.cards?.filter((card) => card.category === "Loan").length / cardsPerPage_Personal) }).map((_, index) => (
            <li key={index} className='mx-1'>
                <button
                    className={`px-3 py-1 rounded-lg ${currentPage_Personal === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                        }`}
                    onClick={() => paginatePersonal(index + 1)}
                >
                    {index + 1}
                </button>
            </li>
        ))}
</ul> */}



                    {/* <h1 className='my-5 text-center text-4xl font-extrabold'>PERSONAL LOAN</h1> */}
                    {/* <h4 className='mt-20 mb-5 text-center text-3xl text-blue-900 font-bold underline'>COMPARISON</h4>
                        <div className='w-[90%] mx-auto'>
                            <table className="min-w-full table-auto">
                                <thead className='sticky top-0'>
                                    <tr>
                                        <th className="px-4 py-3 bg-blue-900 text-white"></th>
                                        <th className="px-4 py-3 bg-blue-900 text-white">Bank Name</th>
                                        <th className="px-4 py-3 bg-blue-900 text-white">Interest Range</th>
                                        <th className="px-4 py-3 bg-blue-900 text-white">Loan Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        LoanData && LoanData.map((item, index) => {
                                            return <tr key={index} className='even:bg-gray-100 border hover:bg-blue-100'>
                                                <td className="font-medium text-center px-4 py-5">{index + 1}</td>
                                                <td className="font-medium text-center px-4 py-5">{item.Bank_Name}</td>
                                                <td className="text-center px-4 py-5">{item.Interest_Range}</td>
                                                <td className="text-center px-4 py-5">{item.Loan_Amount}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div> */}
                </div>

                <div className='w-[95%] mx-auto py-10'>
                    <h1 className='text-center font-bold text-2xl md:text-5xl my-10'>Frequently Asked Questions</h1>
                    <AccordionComponent />
                </div>
            </div>

            {/* <FooterComponent /> */}
            {/* </div> */}
        </>
    )
}

export default Home
