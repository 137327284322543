// import React, { useEffect, useState } from 'react';

// const PageCategory = ({ onSelectCategory, activeCategory }) => {
//   const [categories, setCategories] = useState([]);

//   useEffect(() => {
//     async function fetchCategories() {
//       try {
//         const response = await fetch('https://test.thefinmaster.com/console/react-app-directory/test-blog-api.php?action=blogs');
//         const data = await response.json();

//         if (data.blogs) {
//           // Extract unique categories from the blogs array
//           const uniqueCategories = [...new Set(data.blogs.map(blog => blog.content_category))];
//           setCategories(uniqueCategories);
//         } else {
//           console.error('Error: Blogs not found in API response.');
//         }
//       } catch (error) {
//         console.error('Error fetching categories:', error);
//       }
//     }

//     fetchCategories();
//   }, []);

//   return (
//     <div className='px-4 mb-8 lg:space-x-16 flex flex-wrap items-center border-b-2 py-5 text-gray-900 font-semibold shadow-md'>
//       <button onClick={() => onSelectCategory(null)} className={`lg:ml-12 ${activeCategory ? "" : "active-button"}`}>
//         All
//       </button>
//       {categories.map((category) => (
//         <button
//           onClick={() => onSelectCategory(category)}
//           className={`mr-2 pl-2 space-x-16 py-2 ${activeCategory === category ? "active-button" : ""}`}
//           key={category}>
//           {category}
//         </button>
//       ))}
//     </div>
//   );
// };

// export default PageCategory;



import React, { useRef, useEffect, useState } from 'react';

const PageCategoryLoan = ({ categories, onSelectCategory, activeCategory }) => {
  const categoryRefs = useRef([]);

  return (
    <div className="category-container">
      <button
        ref={(el) => (categoryRefs.current[0] = el)}
        onClick={() => onSelectCategory(null)}
        className={`category-button ${!activeCategory ? "active" : ""}`}
      >
        All
      </button>
      {categories.length > 0 && categories.map((category, index) => (
        <button
          ref={(el) => (categoryRefs.current[index + 1] = el)}
          onClick={() => onSelectCategory(category)}
          className={`category-button ${activeCategory === category ? "active" : ""}`}
          key={category}
        >
          {category}
        </button>
      ))}
    </div>
  );
};

export default PageCategoryLoan;
