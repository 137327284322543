import React from 'react';

const LoanPagination = ({ onPageChange, currentPage, loan = [], pageSize }) => {
    // Ensure loan is an array and handle undefined or null cases
    const totalPages = loan.length ? Math.ceil(loan.length / pageSize) : 0;
  
    const renderPaginationLinks = () => {
        return Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
            <li className={pageNumber === currentPage ? "activePagination" : ""} key={pageNumber}>
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    onPageChange(pageNumber);
                }}>
                    {pageNumber}
                </a>
            </li>
        ));
    };

    return (
        <>
            <div>
                <ul className='pagination my-8 flex-wrap gap-4'>
                    <li>
                        <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                            Previous
                        </button>
                    </li>
                    <div className='flex gap-1'>
                        {renderPaginationLinks()}
                    </div>
                    <li>
                        <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                            Next
                        </button>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default LoanPagination;

// import React from 'react'

// const LoanPagination = ({onPageChange, currentPage, loan, pageSize}) => {
//     const totalPages = Math.ceil(loan.length / pageSize);
//   console.log(totalPages)
// const renderPaginationLinks =() =>{
//     return Array.from({length: totalPages}, (_, i) => i+1).map((pageNumber) => (
//         <li className={pageNumber === currentPage ? "activerPagination" : ""} key={pageNumber}>
//             <a href='#' onClick={() => onPageChange(pageNumber)}>{pageNumber}</a>
//         </li>
//     ))
// }
//     return (
//         <>
//     <div>
//       <ul className='pagination my-8 flex-wrap gap-4'
//       >
//         <li>
//             <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage ===1}>Privious</button>
//         </li>
//         <div className='flex gap-1'>
//              {renderPaginationLinks()}  </div>
//            <li>
//             <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
//             </li>
       
//       </ul>
//     </div>
//     </>
//   )
// }

// export default LoanPagination
