import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import InsuranceUiPage from './InsuranceUiPage';
import FAQSchema from '../../FAQSchema';

const InsuranceMainPage = () => {
  function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
  }
  
  
    const [activeIndex, setActiveIndex] = useState(0);
  
    const faqs = [
        {
            question: '  What is insurance?',
            answer:
                'Insurance is a financial arrangement where individuals or organisations pay premiums to an insurance provider in exchange for protection against potential financial losses or risks. It serves as a risk management tool to mitigate the impact of unexpected events.',
        },
        {
            question: 'How does insurance work?',
            answer:
                'When you purchase insurance, you agree to pay regular premiums to the insurance company. In return, the insurer provides financial coverage for specified risks, such as property damage, illness, or death, depending on the policy. If a covered event occurs, the insurer compensates you according to the policy terms.',
        },
        {
            question: '   What types of insurance are available?',
            answer:
                ' Common types of insurance include health insurance, auto insurance, life insurance, homeowners insurance, and disability insurance. Each type provides coverage for specific risks and circumstances.',
        },
        {
            question: 'Why do I need insurance? ',
            answer:
                '  Insurance helps manage financial risks by providing a safety net against unforeseen events. It reduces the financial burden of emergencies, such as medical expenses, property damage, or loss of income, and offers peace of mind.',
        },
        {
          question: ' What is the difference between term and whole life insurance? ',
          answer:
              'Term life insurance provides coverage for a specific period, such as 10 or 20 years, and pays out a benefit only if the insured person dies within that term. Whole life insurance offers coverage for the insured entire lifetime and includes a savings component that builds cash value over time.',
      },
      {
        question: 'Can I change my insurance policy?',
        answer:
            ' Yes, you can often modify your insurance policy to adjust coverage levels, add or remove beneficiaries, or update personal information. Contact your insurance provider to discuss any changes you wish to make.',
    },
    {
      question: 'What should I consider when choosing an insurance policy?',
      answer:
          ' When selecting an insurance policy, consider factors such as the coverage options, premium costs, deductibles, exclusions, and the insurer reputation. Ensure the policy meets your specific needs and financial situation.',
  },
  {
    question: 'How do I file an insurance claim?',
    answer:
        'To file a claim, contact your insurance provider and provide details about the event or loss. You will need to complete a claim form and submit any required documentation. The insurer will review your claim and determine the amount of compensation based on the policy terms.',
},
        
    ];
  
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    }
  
  return (
    <>
      {/* <Helmet>
        <title>What is Demat Account - Meaning, Features, Types, Benefits</title>
        <meta 
          name='description' 
          content='A demat account helps investors to hold their shares and securities in an electronic format. Know its meaning, types, features, benefits, and more on thefinmaster.'
        />
        <meta 
          name='keywords' 
          content='what is demat and trading account, what is demat account meaning' 
        />
        <link rel="canonical" href="https://thefinmaster.com/insurance_page" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet> */}
<FAQSchema faqs={faqs} />
      <nav className="text-gray-500 text-sm my-2 ml-10" aria-label="Breadcrumb">
        <ol className="list-none p-0 inline-flex">
          <li className="flex items-center">
            <Link to="/" className="text-blue-600 hover:text-blue-800">Home</Link>
            <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path 
                fillRule="evenodd" 
                d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" 
                clipRule="evenodd" 
              />
            </svg>
          </li>
          <li className="flex items-center">
            <span className="text-gray-500">Insurance Page</span>
          </li> 
        </ol>
      </nav>

      <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Shield your family from life’s surprises 
                  </span>{" "}
                  with robust insurance. 
                </h1>
                
                <a href="https://www.acko.com/two-wheeler-insurance/"
                 target="_blank" 
                 rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Open Account!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/insure.jpeg' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
       <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 flex justify-center items-center">
    <p className="text-lg md:text-base lg:text-lg text-center">
    In today's fast-paced environment, ease of use is paramount. Insurance offers a robust solution, merging simplicity with extensive coverage. Forget about potential uncertainties – a well-chosen policy ensures you’re protected against a range of risks. Beyond mere convenience, the benefits are substantial. At TheFinMaster.com, we’ll navigate you through the dynamic landscape of insurance, helping you secure the ideal protection for your needs.   </p>
    </div>
      <div >
<InsuranceUiPage />
            </div>
             {/* FAQ section */}
    <div className="max-w-4xl mx-auto py-6 mt-10">
                <h1 className="text-center text-2xl md:text-2xl lg:text-5xl font-bold mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
    </>
  );
}

export default InsuranceMainPage;
