import React from 'react';
import { Link } from 'react-router-dom';

const LoanCard = ({ loan, currentPage, selectedCategory, pageSize }) => {
    const filteredloan = Array.isArray(loan) ? loan : [];

    const paginatedloan = filteredloan
        .filter((loan) => !selectedCategory || loan.category_name === selectedCategory)
        .slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <div className='lg:px-48 px-4'>
            {paginatedloan.map((loan, index) => (
                <div
                    key={`${loan.page_url}-${index}`}
                    className='md:w-[900px] w-full py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-0 md:ml-6 mt-4'
                >
                    <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                        <div className='w-full md:w-[220px] overflow-hidden py-2 md:ml-2'>
                            <img className='w-full h-full object-contain' alt='' src={loan.image} />
                        </div>
                    </div>

                    <div className='w-full md:pl-0 pl-4 mt-6'>
                        <p className='text-gray-500 font-medium'>Interest Rate </p>
                        <h4 className='text-2xl py-0.5 font-bold'>{loan.interest_rate}</h4>
                    </div>

                    <div className='w-full md:pl-0 pl-4 mt-6'>
                        <p className='text-gray-500 font-medium'>Loan Amount</p>
                        <h4 className='text-2xl py-0.5 font-bold'>{loan.loan_amount}</h4>
                    </div>

                    <div className='w-full md:pl-0 pl-4 mt-6'>
                        <p className='text-gray-500 font-medium'>Processing Fee</p>
                        <h4 className='text-2xl py-0.5 font-bold'>{loan.processing_fee}</h4>
                    </div>

                    <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2 '>
                        <Link to={`/loan_page/${loan.page_url}`}>
                            <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 w-full md:w-auto'>
                                See Details
                            </button>
                        </Link>
                        
                        <a 
                            href= {loan.bank_link}
                            target="_blank" 
                            rel="noopener noreferrer"
                            className='w-full md:w-auto'
                        >
                            <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300 w-full md:w-auto">
                                Get Quote!
                            </button>
                        </a>
                    </div>
                </div>
            ))}
        </div>
        
    );
};

export default LoanCard;
