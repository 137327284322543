import React, { useEffect, useState } from 'react';
import { Loan_API } from '../../Helper';
import LoanCard from './LoanCard';
import PageCategoryLoan from './PageCategoryLoan';
import LoanPagination from './LoanPagination';


const LoanUiPage = () => {
  const [loan, setLoans] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of blogs per page
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [categories, setCategories] = useState([]);


  useEffect(() => {
    async function fetchLoan() {
      let url = Loan_API;

      if (currentPage || selectedCategory) {
        url = `https://test.thefinmaster.com/console/react-app-directory/loan-api.php?page=${currentPage}&limit=${pageSize}`;
        if (selectedCategory) {
          url += `&category=${selectedCategory}`;
        }
      }

      try {
        const response = await fetch(url);
        const data = await response.json();
        const transformedData = data.loan.map((loan, index) => ({
          ...loan,
          id: `${loan.page_url}-${index}`  // Modify the ID here
          // Modify the ID here
        }));
        setLoans(transformedData);
// Extract unique categories from card
const uniqueCategories = [...new Set(transformedData.map(loan => loan.category_name))];
setCategories(uniqueCategories);
        // setLoans(data.loan || data); // Adjust depending on your API response structure
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchLoan();
  }, [currentPage, pageSize, selectedCategory]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
    setActiveCategory(category);
  };

  return (
    <div>
      {/* Category Section */}
      <div>
        {/* <PageCategory onSelectCategory={handleCategoryChange} selectedCategory={selectedCategory} 
       activeCategory={activeCategory} /> */}
       {/* for Dynamically */}
       <PageCategoryLoan onSelectCategory={handleCategoryChange}  categories={categories} 
       activeCategory={activeCategory} />
      </div>

      {/* Blog Card Section */}
      <div >
        <LoanCard loan={loan} currentPage={currentPage} selectedCategory={selectedCategory} pageSize={pageSize} />
{/*  Sidebar */}
{/* <div>
    <SideBar />
</div> */}

      </div>

      {/* Pagination Section */}
      <div>
        <LoanPagination onPageChange={handlePageChange} currentPage={currentPage} loan={loan} pageSize={pageSize}/>
      </div>
    </div>
  );
};

export default LoanUiPage;

// import React, { useEffect, useState } from 'react'
// import LoanCard from './LoanCard'

// const LoanUiPage = () => {
//     const [loan, setLoans] =useState([])
//     useEffect(() => {
//         async function fetchLoan() {
//             let url ='https://test.thefinmaster.com/console/react-app-directory/loan-api.php'
//         const response = await fetch(url);
//         const data = await response.json();
//         console.log(data);
//         setLoans(data);
//         }
//         fetchLoan() ;
//     }, [])
//   return (
    
//     <div>
//     <div>
//       <LoanCard loan ={loan} />
//     </div>
//     </div>
//   )
// }

// export default LoanUiPage
