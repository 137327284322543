import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import SideBar from './SideBar';
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';

const SingleLoanPage = () => {
  const { id } = useParams();
//   console.log('ID from useParams:', id);
  const [loan, setLoan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const fetchLoan = async () => {
      try {
        //  console.log(`Fetching card with ID: ${id}`);
        const response = await fetch(`https://test.thefinmaster.com/console/react-app-directory/loan-api.php?id=${id}`);
        
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        
        const data = await response.json();
        
        // Log data to understand the structure
        // console.log("Fetched Data:", data);

        // Extract the blogs array
        // const blogs = data.blogs;
        
        // Find the specific blog by ID
        // const foundBlog = blogs.find(blog => blog.url === id);
        const foundLoan = data.loan.find(loan => loan.page_url === id);

        // console.log("Found loan:", foundLoan);
        
        setLoan(foundLoan);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLoan();
  }, [id]);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
        setActiveIndex(null);
    } else {
        setActiveIndex(index);
    }
};
  if (loading) {
    return<div class="spinnerContainer">
    <div class="spinner"></div>
    <div class="loader">
      <p>loading</p>
      <div class="words">
        <span class="word">images</span>
        <span class="word">Title</span>
        <span class="word">Card</span>
        <span class="word">Bank Card</span>
      </div>
    </div>
  </div>
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!loan) {
    return <p>No Card found.</p>;
  }
// const {content_category, content_date, 
//     content_description, 
//     content_image, 
// content_keyword, content_meta,
// content_meta_description,
// content_title} = blogs[0];

  return (
    <>
    <Helmet>
        <title>{loan.metatitle}</title>
        <meta name='description' content={loan.metadesc} />
        <meta name='keywords' content={loan.metakeyword} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <FAQSchema faqs={loan.loan_faq} />
      <nav className="text-gray-500 text-sm my-4 ml-12" aria-label="Breadcrumb">
        <ol className="list-none p-0 inline-flex">
          <li className="flex items-center">
            <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
            <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link to="/loan_page" className="text-blue-600 hover:text-blue-800">Loan Page</Link>
            <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>

        </ol>
      </nav>
      <div className="container mx-auto px-4 py-8">
  <div className="flex flex-col lg:flex-row gap-12">
    <div className="lg:w-3/4">
      <div className="w-full mx-auto">
        <img
          src={loan.image}
          alt={loan.page_title}
          className="w-full h-auto rounded"
        />
      </div>
      <h2 className="text-3xl mt-8 font-bold mb-4 text-blue-500 cursor-pointer">
        {loan.page_title}
      </h2>
      <p className="text-2xl mt-4 font-semibold mb-4 text-gray-700">
        {loan.page_description}
      </p>
      
    </div>

    {/* Uncomment and customize the sidebar as needed */}
    {/* <div className='lg:w-1/4'>
      <SideBar />
    </div> */}
  </div>
</div>
<div className="text-gray-700 text-base mb-6 px-12 ">
        <p dangerouslySetInnerHTML={{ __html: loan.page_details }}></p>
      </div>
      <div className="max-w-4xl mx-auto py-6">
                        <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                        <div className="space-y-4">
                            {loan.loan_faq.map((faq, index) => (
                                <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                                    <button
                                        onClick={() => toggleAccordion(index)}
                                        className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                                    >
                                        {faq.question}
                                        <svg
                                            className={`w-6 h-6 transition-transform transform ${
                                                activeIndex === index ? 'rotate-180' : ''
                                            }`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d={activeIndex === index ? 'M19 9l-7 7-7-7' : 'M9 5l7 7 7-7'}
                                            />
                                        </svg>
                                    </button>
                                    {activeIndex === index && (
                                        <div className="p-4 bg-gray-100">
                                            <p className="text-gray-600">{faq.answer}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
    </>
  
  );
};

export default SingleLoanPage;
