import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import LoanUiPage from "./LoanUiPage"
import FAQSchema from "../../FAQSchema"
const LoanMainPage= () => {
  
  function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
  }
  
  
    const [activeIndex, setActiveIndex] = useState(0);
  
    const faqs = [
        {
            question: ' What is a loan?',
            answer:
                'A loan is borrowed money that must be repaid with interest over a set period. The borrower receives a principal amount and pays back the loan amount plus interest.',
        },
        {
            question: 'How do I qualify for a loan?',
            answer:
                'Qualification factors include credit score, income, employment status, and debt-to-income ratio.',
        },
        {
            question: '   What is an interest rate?',
            answer:
                'The interest rate is the percentage of the loan amount charged as the cost of borrowing. It can be fixed (unchanging) or variable (fluctuating).',
        },
        {
            question: 'What is a loan term?',
            answer:
                '  The loan term is the length of time over which you will repay the loan, such as 15 or 30 years.',
        },
        {
          question: 'How does loan repayment work?',
          answer:
              'Repayments are made in regular instalments that include both principal and interest.',
      },
      {
        question: 'Can I repay my loan early?',
        answer:
            'Yes, but check for any prepayment penalties that might apply.',
    },
    {
      question: 'How do I apply for a loan?',
      answer:
          'Apply through a bank, credit union, or online lender by submitting an application and required documents.',
  },
    ];
  
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    }
  
    return(
        <>
        {/* <Helmet>    
      <title>TheFinmaster Blog - Articles on Life Insurance, Term Insurance, Health Insurance, Moter Insurance, Bike Insurance,Tax, Investment, Retirement Planning</title>
      <meta  name='description' content='Check out informative blogs on term insurance, life insurance,Health Insurance, Moter-Car Insurance, Bike Insurance, Study Loan, tax savings, investments, retirement planning, child insurance, cancer insurance &amp; more on Max Life Insurance.'/>
      <meta name='keywords' content=' insurance blogs, travel insurance blog, auto insurance blog, health insurance blog, life insurance blog' />
      <link rel="canonical" href="https://thefinmaster.com/blog_page" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet> */}
    <FAQSchema faqs={faqs} />
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Loan Page</span>
        </li> 
    
      </ol>
    </nav>
      <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Discover personalized loan solutions crafted 
                  </span>{" "}
                  just for you by leading lenders.
                </h1>
                {/* <a href="#insurance_plans">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    See Plans
                  </button>
                </a> */}
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src="../assets/newloan.jpeg"
                alt="car-loan"
              />
            </div>
          </div>
       </div>
       <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 flex justify-center items-center">
    <p className="text-lg md:text-base lg:text-lg text-center">
    In our fast-paced society, convenience is essential. Loans offer a practical solution, merging easy application processes with substantial financial aid. Don’t worry about funding challenges – a well-selected loan ensures you have the resources for your needs. The benefits extend beyond convenience. At TheFinMaster.com, we’ll guide you through the complex loan market to find the ideal option for your financial requirements. </p>
    </div>
            <div >
<LoanUiPage />
            </div>
        </div>
         {/* FAQ section */}
    <div className="max-w-4xl mx-auto py-6 mt-10">
                <h1 className="text-center text-2xl md:text-2xl lg:text-5xl font-bold mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
export default LoanMainPage